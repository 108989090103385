import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { Sidemenu } from "../components/Navigation/Sidemenu"
import { graphql } from "gatsby"
import { CurveCarveTop, CurveBulgeBottom } from "../components/Curves/Curve"
import styled from "styled-components"
import { mediaBreakpoint } from "../utils/breakpoints"

import { CallMeBackFull } from "../components/Misc/CallMeBackFull"
import { MarkdownCommon } from "../components/Markdown/MarkdownCommon"

const StyledPageWrapper = styled.div`
  /* If the page isn't long enough for the menu... */
  overflow: auto;

  /* The point where the menu breaks underneath: */
  @media ${mediaBreakpoint.down.md} {
    overflow: visible;
  }
`

const Page = ({ data: { contentfulAboutPage: page, aboutPage } }) => {
  return (
    <Layout>
      <SEO
        title={page.seoTitle}
        description={page.seoDescription && page.seoDescription.seoDescription}
      />

      <StyledPageWrapper>
        <Container>
          <Row>
            <Col>
              <h1>{page.title}</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              {page.introduction && (
                <div className="content-body">
                  <MarkdownCommon body={page.introduction} />
                </div>
              )}
            </Col>
            <Col lg={4} className="d-none d-lg-block">
              <Sidemenu
                title={aboutPage.title}
                titleLink={aboutPage.permalink}
                pages={aboutPage.childPages}
              />
            </Col>
          </Row>
        </Container>
        <CurveCarveTop variant="light" />
        <div className="bg-light pt-5">
          <Container>
            <Row>
              <Col lg={8}>
                {page.body && (
                  <div className="content-body">
                    <MarkdownCommon body={page.body} />
                  </div>
                )}
              </Col>
              <Col className="d-lg-none">
                <Sidemenu
                  title={aboutPage.title}
                  titleLink={aboutPage.permalink}
                  pages={aboutPage.childPages}
                />
              </Col>
            </Row>
          </Container>
        </div>

        <CurveBulgeBottom variant="light" />
      </StyledPageWrapper>

      <CallMeBackFull />
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String) {
    contentfulAboutPage(id: { eq: $id }) {
      id
      contentful_id
      title
      seoTitle
      seoDescription {
        seoDescription
      }
      permalink
      introduction {
        childMarkdownRemark {
          htmlAst
        }
      }
      body {
        childMarkdownRemark {
          htmlAst
        }
      }
    }
    aboutPage: contentfulAboutPage(
      contentful_id: { eq: "6L2788ZRmNoAmnleljcjHv" }
    ) {
      contentful_id
      title
      permalink
      childPages {
        id
        contentful_id
        title
        permalink
      }
    }
  }
`

export default Page
